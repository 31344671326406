import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import MasonryGallery from "../components/MasonryGallery"
import Modal from './Modal'

export default function GalleryBlock(props) {
    const data = useStaticQuery(graphql`
    query GalleryBlockQuery {
        allWpGallery(limit: 13, filter: {status: {eq: "publish"}}) {
            edges {
              node {
                id
                title
                featuredImage {
                  node {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(quality: 60)
                      }
                    }
                  }
                }
              }
            }
          }
        }
  `)

    const { ...rest } = props;

    return (
        <div {...rest}>
            <MasonryGallery renderAs={`ul`} className={`p-0 -m-1.5 xl:-m-1`}>
            {
                    data.allWpGallery.edges.map(img => {

                        const imageData = getImage(img.node?.featuredImage?.node?.localFile)

                        return  (
                            <li className={`w-1/2 md:w-1/3 lg:w-1/4 m-0 p-1.5 xl:p-1`} key={img.node.id}>
                                <figure>
                                  <Modal>
                                <GatsbyImage 
                                    image={imageData}
                                    alt={img.node.featuredImage.node.altText || img.node.title}
                                    layout={`fullWidth`}
                                />
                                </Modal>
                                </figure>
                            </li>
                        )
                    })
                }
            </MasonryGallery>
        </div>
    )
}
