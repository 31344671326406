import * as React from "react"
import Layout from "../components/Layout"
import Seo from "../components/seo"
import { useStaticQuery, graphql } from "gatsby"
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image"
import Container from "../components/Container"
import { PageTitle } from "../components/Titles";
import GalleryBlock from "../components/GalleryBlock"

const AboutPage = () => {
  const data = useStaticQuery(graphql`
    query AboutPageQuery {
      wpContentSnippet(databaseId: {eq: 205}) {
        content
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
            altText
            caption
          }
        }
      }
        }
  `)
  
  return (
    <Layout>
      <Seo title="About" />
        <Container className={`py-16 md:py-20 md:pb-28`} size={`lg`} style={{minHeight: '80vh'}}>
            <div className={`flex flex-row items-start md:flex-nowrap flex-wrap md:-mx-5 lg:-mx-10`}>
              <div className={`flex-true md:w-auto max-w-full md:px-5 lg:px-10`}>
              <PageTitle size={`text-5xl md:text-6xl font-medium md:mb-2`}>About<br/>Milo<br/>Walker<br/>Johnson</PageTitle>
                
                {
                  data.wpContentSnippet.content &&

                  <div 
                  className={`pt-10`}
                dangerouslySetInnerHTML={{__html: data.wpContentSnippet.content}}
                />
                }
              </div>
              <div className={`md:block hidden flex-true md:w-auto max-w-full md:px-5 lg:px-10 md:flex-[45%] md:max-w-[45%]`}>
                  {data.wpContentSnippet.featuredImage ? (<>
                    <GatsbyImage 
                    image={getImage(data.wpContentSnippet.featuredImage?.node?.localFile)}
                    alt={data.wpContentSnippet.featuredImage.node.altText || `Milo Walker Johnson`}
                    />
                    {data.wpContentSnippet.featuredImage.node.caption && <div className={`font-semibold text-xs mt-2 px-2`} dangerouslySetInnerHTML={{__html: data.wpContentSnippet.featuredImage.node.caption}}></div>}
                  </>) : (
                    <StaticImage 
                      src={`../images/about/milo-DanforthMusicHall-TorontoON.jpg`}
                      alt={`Milo Walker Johnson`}
                      />
                  )
                }
                  
                  
              </div>
            </div>
            <GalleryBlock className={`mt-10 pt-10 md:mt-[3.25rem] md:pt-[3.25rem] lg:mt-16 lg:pt-16`} />
        </Container>
      
 
    </Layout>
  )
}

export default AboutPage
